html {
  background: rgb(80, 204, 176);
  font-family: "Pacifico", cursive;
}

body {
  height: 100vh;
  margin: 0;
}

#root {
  padding-top: 100px;
  text-align: center;
}

h1 {
  font-size: 3em;
  margin-top: 0;
  margin-bottom: 100px;
}

button {
  background: rgb(243, 186, 139);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border: 10px solid rgb(136, 93, 63);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-family: "Indie Flower", cursive;
  font-size: 14px;
  height: 70px;
  outline: none;
  transition: box-shadow 0.3s ease;
  width: 100px;
}

button:hover {
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.6);
}

button:active {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

img {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: auto;
  right: 0;
  width: 750px;
  z-index: -1;
}
