.ReactToast {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Arial, Helvetica, sans-serif;
  width: calc(100vw - 20px);
  min-height: 80px;
  background: rgb(255, 255, 255);
  position: fixed;
  bottom: 10px;
  right: -100vw;
  transition: right 1s ease-in;
  z-index: 100000;
  line-height: 1.25;
  padding: 10px 10px 10px 6px;
  text-align: left;
  color: #333;
  border-left: 12px #e5e5e5 solid;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  letter-spacing: -0.5px;
  box-sizing: border-box;
}

.ReactToast:hover {
  cursor: default;
}

@media (min-width: 480px) {
  .ReactToast {
    top: 10px;
    bottom: initial;
    width: 300px;
  }
}

.ReactToast.success {
  border-left-color: #2bde3f;
}

.ReactToast.info {
  border-left-color: #1d72f3;
}

.ReactToast.warning {
  border-left-color: #ffc007;
}

.ReactToast.error {
  border-left-color: rgb(235, 77, 96);
}

.ReactToast.isOpen {
  transition: right 1s ease-out;
  right: 10px;
}

.ReactToast--title {
  margin: 0 0 5px 0;
  font-size: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactToast--description,
.ReactToast p {
  font-size: 14px;
}

.ReactToast--close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 24px;
  padding-right: 5px;
  color: rgba(0, 0, 0, 0.6);
}
